import { IQuiz } from '../IQuiz'

export const ocpPatterns5: IQuiz = {
  name: 'ocp-patterns-5',
  question: 'В чём польза шаблона «Декоратор»?',
  variants: [
    {
      text: 'В расширении функциональности модулей через обёртки без изменения кода этих модулей'
    },
    {
      text:
        'В возможности расширять функциональность классов и методов динамически, без необходимости использовать наследование'
    },
    {
      text: 'В возможности выделить логику, не относящуюся напрямую к функциональности модуля в другую сущность'
    }
  ],
  meta: {
    correctAnswers: [0, 1, 2]
  }
}
