import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Допустим, нам требуется добавить в ", _jsx(_components.code, {
        children: "AreaCalculator"
      }), " подсчёт площади треугольника из примера ниже"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "class Triangle {\n  base: number\n  height: number\n\n  constructor(base: number, height: number) {\n    this.base = base\n    this.height = height\n  }\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Класс ", _jsx(_components.code, {
        children: "Triangle"
      }), " не имплементирует интерфейс ", _jsx(_components.code, {
        children: "Shape"
      }), ", который в проекте существует давно. Что необходимо сделать, чтобы код не нарушал OCP?"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
