import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Допустим, дан класс более общего наблюдаемого объекта:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "class ConcreteObservable implements Observable {\n  listeners: { [key: string]: Observer[] } = {}\n\n  subscribe(event: string, observer: Observer): void {\n    // Добавить объект в `this.listeners[event]`.\n  }\n\n  unsubscribe(event: string, observer: Observer): void {\n    // Удалить объект из `this.listeners[event]`.\n  }\n\n  notify(event: string, data: any): void {\n    // Уведомить всех из `this.listeners[event]`.\n  }\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Какой из интерфейсов он реализует?"
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
