import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Что можно вынести за фасад из примера ниже?"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "enum PlayerTypes { Audio, Video }\n\nclass Player implements IPlayer {\n  player: AnyPlayer\n\n  constructor(playerType: PlayerTypes) {\n    this.player = playerType === PlayerTypes.Audio\n      ? new AudioPlayer()\n      : new VideoPlayer()\n  }\n\n  loadTrack(trackSource: Track) {\n    this.player.load(trackSource)\n    this.player.init()\n    this.player.play()\n  }\n}\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
