import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_components.p, {
    children: ["Абстрактный класс ", _jsx(_components.code, {
      children: "Programmer"
    }), " реализует интерфейс ", _jsx(_components.code, {
      children: "SodaConsumer"
    }), ", который содержит метод ", _jsx(_components.code, {
      children: "drinkSoda"
    }), ". Программисты класса ", _jsx(_components.code, {
      children: "OnDietProgrammer"
    }), " не пьют газировку, поэтому этот метод им не нужен, но они должны его реализовать из-за интерфейса родительского класса"]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
