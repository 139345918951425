import { IQuiz } from '../IQuiz'

export const srpIntro2: IQuiz = {
  name: 'srp-intro-2',
  question: 'Какую пользу приносит принцип единственной ответственности?',
  variants: [
    {
      text: 'Позволяет уменьшить количество модулей, которые нужно изменить при изменении бизнес-требований'
    },
    {
      text: 'Всегда делает модули меньше, компактнее',
      description:
        'Иногда приходится объединять код, меняющийся по одной причине вместе, тогда модули могут стать больше'
    },
    { text: 'Побуждает создавать модули, которые меняются лишь по одной причине' },
    {
      text: 'Уменьшает количество используемых в коде абстракций за счёт уменьшения размеров сущностей',
      description:
        'Иногда приходится объединять код, меняющийся по одной причине вместе, тогда модули могут стать больше'
    }
  ],
  meta: {
    correctAnswers: [0, 2]
  }
}
