import * as React from 'react'

import Variant1 from './variants/variant-1.mdx'
import { IQuiz } from '../IQuiz'

export const srpIdeal1: IQuiz = {
  name: 'srp-ideal-1',
  question: 'В чём главная причина того, что выбор формата данных вынесен в отдельный класс?',
  variants: [
    {
      text: <Variant1 />
    },
    {
      text:
        'Потому что иначе код других классов сильно разрастается. Без этого чтение и понимание становятся гораздо труднее',
      description:
        'Да, но это лишь следствие главной причины — выбор формата не относится ни к форматированию данных, ни к подготовке их к экспорту'
    },
    {
      text:
        'Это удобнее с точки зрения управления зависимостями. В этом случае подключение функциональности выбора формата производится в одном месте',
      description:
        'Вынесение его в отдельный модуль действительно облегчает управление зависимостями, но это тоже лишь следствие основной причины'
    }
  ],
  meta: {
    correctAnswers: [0]
  }
}
