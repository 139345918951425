import { IQuiz } from '../IQuiz'

export const ocpPatterns9: IQuiz = {
  name: 'ocp-patterns-9',
  question: 'В чём польза замены прямого наследования на полиморфизм?',
  variants: [
    {
      text: 'Исключает зависимость одних модулей от других',
      description: 'Приём не исключает зависимость, а лишь ослабляет зацепление модулей'
    },
    {
      text: 'Ослабляет зацепление модулей через введение абстракций'
    },
    {
      text: 'Гарантирует, что изменение в одном модуле перестанут вызывать изменения в другом',
      description: 'Приём помогает достичь такой ситуации, но не гарантирует её'
    }
  ],
  meta: {
    correctAnswers: [1]
  }
}
