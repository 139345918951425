import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_components.p, {
    children: [_jsx(_components.code, {
      children: "Square"
    }), " неверно имплементирует интерфейс ", _jsx(_components.code, {
      children: "AreaCalculatable"
    }), ", в частности метод ", _jsx(_components.code, {
      children: "calcArea"
    }), " должен называться ", _jsx(_components.code, {
      children: "areaOf"
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
