import Question from './question.mdx'
import { IQuiz } from '../IQuiz'

export const ocpAnti1: IQuiz = {
  name: 'ocp-anti-1',
  question: <Question />,
  variants: [
    {
      text: 'Каждый из них реализует только часть принципа: либо делает модуль открытым для расширения, либо закрытым для изменения',
      description: 'Проблема всех подходов в повышении зацепления модулей, которое нарушает обе части принципа'
    },
    {
      text: 'Каждый из них увеличивает зацепление модулей друг с другом, что нарушает основу принципа'
    },
    {
      text: 'Каждый из них приводит к ситуации, когда при изменении требований приходится менять код уже существующих модулей'
    }
  ],
  meta: {
    correctAnswers: [1, 2]
  }
}
