import Variant3 from './variant-3.mdx'
import { IQuiz } from '../IQuiz'

export const dipPatterns2: IQuiz = {
  name: 'dip-patterns-2',
  question: 'В чём особенности разных видов инъекции?',
  variants: [
    {
      text: 'Инъекция через сеттеры не раздувает конструктор, но добавляет методы-инжекторы в класс',
      description: 'Этот тип инъекции действительно не раздувает конструктор, но не добавляет методы-инжекторы'
    },
    {
      text: 'Инъекция через конструктор делает код конструктора больше, но не меняет методы и поля класса'
    },
    {
      text: <Variant3 />,
      description: 'Этот тип инъекции действительно добавляет методы-инжекторы, но не меняет видимость полей класса'
    },
    {
      text: 'Инъекция через интерфейс добавляет методы-инжекторы, но не раздувает конструктор'
    }
  ],
  meta: {
    correctAnswers: [1, 3]
  }
}
