import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    li: "li",
    ol: "ol",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {}), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "class CachedRequest {\n  client: RequestClient\n  cache: { [name: string]: any }\n\n  constructor(client: RequestClient) {\n    this.client = client\n    this.cache = {}\n  }\n\n  async request(url: string): Promise<any> {\n    if (this.cache[url]) return Promise.resolve(this.cache[url])\n    const result = await this.client.request(url)\n    this.cache[url] = result\n    return result\n  }\n}\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
