import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Допустим, мы решили использовать выделение интерфейса и суперкласса для решения проблемы «пустых» реализаций. У нас есть интерфейсы:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "interface Article {\n  published: boolean\n}\n\ninterface Publishable {\n  publish(): void\n}\n\ninterface Deletable {\n  delete(): void\n}\n\ninterface Removable {\n  unpublish(): void\n}\n\ninterface Restorable {\n  restore(): void\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Какой из способов ниже может помочь справиться с «пустыми» реализациями?"
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
