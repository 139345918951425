import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Допустим, у нас есть класс ", _jsx(_components.code, {
        children: "EventEmitter"
      }), ", события которого мы хотим записывать в ", _jsx(_components.code, {
        children: "Logger"
      }), ". События из ", _jsx(_components.code, {
        children: "EventEmitter"
      }), " — это строки с пробелами. Пробелы вызовут ошибку в ", _jsx(_components.code, {
        children: "Logger"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "class EventEmitter {\n  public emit(e: EventTypes): string {\n    return e.type\n  }\n}\n\nclass Logger {\n  public logEvent(eventType: string): void {\n    if (eventType.search(/\\s/) > -1) throw new Error('hey! no spaces!')\n    // ...\n  }\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Какой из вариантов ниже подойдёт в качестве адаптера для замены пробелов на дефисы для работы с ", _jsx(_components.code, {
        children: "Logger"
      }), "?"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
