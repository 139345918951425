import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    li: "li",
    ol: "ol",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.ol, {
      start: "3",
      children: ["\n", _jsx(_components.li, {}), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "interface SodaConsumer {\n  drinkSoda(liters: number): void\n}\n\nabstract class Programmer\n  implements CodeProducer, PizzaConsumer, SodaConsumer {\n  constructor() {/*...*/}\n  writeCode(): void {/*...*/}\n  eatPizza(slicesCount: number): void {/*...*/}\n  drinkSoda(liters: number): void {/*...*/}\n}\n\nclass OnDietProgrammer extends Programmer {\n  constructor() {\n    super()\n  }\n}\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
