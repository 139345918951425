import { IQuiz } from '../IQuiz'

export const srpIdeal2: IQuiz = {
  name: 'srp-ideal-2',
  question: 'Какую главную ошибку исправляет выделение форматов данных в отдельные классы?',
  variants: [
    {
      text:
        'Исправляет смешивание разных ответственностей в одном классе. Когда функциональность работы с каждым форматом находится в отдельном классе, изменение правил, относящихся к этому формату, задевает только этот класс'
    },
    {
      text:
        'Уменьшает неоправданно большой размер класса. Когда функциональность работы с каждым форматом находится в отдельном классе, размер классов уменьшается, делая чтение и понимание проще',
      description:
        'Это следствие исправления главной ошибки — неправильного разделения ответственностей между сущностями'
    },
    {
      text:
        'Позволяет применить полиморфизм для работы с классами форматов. Это позволяет сделать код классов чище и однообразнее с точки зрения восприятия',
      description:
        'Конкретно в этом примере — да, но это работает не всегда. Кроме прочего, это также следствие исправления неправильного разделения ответственностей'
    }
  ],
  meta: {
    correctAnswers: [0]
  }
}
