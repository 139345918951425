import { IQuiz } from '../IQuiz'

export const srpAnti2: IQuiz = {
  name: 'srp-anti-2',
  question: 'В чём корень главной проблемы синглтона с точки зрения принципа единственной ответственности?',
  variants: [
    {
      text:
        'Может находиться в большом количестве различных состояний, из-за чего модульные тесты могут показывать непредсказуемые результаты',
      description:
        'Это следствие основной причины — смешения двух задач: основной задачи модуля и проверки существования созданных ранее экземпляров'
    },
    {
      text: 'Берёт на себя помимо основной задачи проверку существования созданных ранее экземпляров'
    },
    {
      text: 'Глобален и излишне имплицитен; трудно заранее понять, как он себя поведёт в какой-то ситуации',
      description: 'Тоже следствие основной причины'
    }
  ],
  meta: {
    correctAnswers: [1]
  }
}
