import Description2 from './description-2.mdx'
import { IQuiz } from '../IQuiz'

export const ocpPatterns1: IQuiz = {
  name: 'ocp-patterns-1',
  question: 'Чем полезен шаблон «Абстрактная фабрика» с точки зрения принципа открытости-закрытости?',
  variants: [
    {
      text: 'Позволяет создавать сущности динамически, инстанциируя классы или создавая объекты внутри себя',
      description: 'Эту задачу решает фабрика, абстрактная фабрика занимается созданием фабрик'
    },
    {
      text: 'Выносит определение нужной фабрики в одно место — конфигуратор приложения',
      description: <Description2 />
    },
    {
      text:
        'Позволяет структурировать код так, чтобы при изменении требований нам потребовалось добавить сущность и изменить как можно меньше уже существующих модулей'
    }
  ],
  meta: {
    correctAnswers: [2]
  }
}
