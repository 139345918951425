import { IQuiz } from '../IQuiz'

export const ocpIntro2: IQuiz = {
  name: 'ocp-intro-2',
  question: 'Какую пользу приносит принцип открытости-закрытости?',
  variants: [
    {
      text: 'Позволяет сократить количество кода, который необходимо менять при изменении бизнес-требований'
    },
    {
      text: 'Снижает сложность системы и уменьшает количество сущностей',
      description:
        'При грамотном использовании сложность можно снизить, но предполагается, что количество сущностей будет расти'
    },
    {
      text: 'Делает внесение изменений безопасным и относительно дешёвым'
    },
    {
      text: 'Изолирует все изменения в требованиях в коде одного модуля',
      description:
        'Наоборот, при следовании принципу новые изменения будут вноситься через новые сущности, не затрагивая код уже существующих модулей'
    }
  ],
  meta: {
    correctAnswers: [0, 2]
  }
}
